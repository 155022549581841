<template>
  <a-row :gutter="25" v-if="eventForm.generalInfo.type != 'other'" style="background: white;">
    <div class="card w-100">
      <a-col :xxl="7" :lg="10" :md="12" :xs="23" style="padding-left: 0" class="box-event">
        <p class="form-font title">Archivos adjuntos</p>
      </a-col>
      <FormValidationWrap>
        <VerticalFormStyleWrap>
          <a-form
            name="sDash_validation-form"
            ref="formRef"
            :model="{ files }"
            :rules="rules"
            :layout="formState.layout"
          >
            <a-row :gutter="30">
              <a-col :lg="9" :xs="24">
                <a-form-item ref="files " name="files" class="title-normal">
                  <a-upload
                    name="files"
                    accept=".pdf, .png, .jpg, .jpng, .xls, .xml, .xlsx, .xmlx, .doc, .docx"
                    :multiple="true"
                    :fileList="files"
                    :remove="deleteFile"
                    :beforeUpload="chooseFiles"
                  >
                    <button class="btn-add-outlined">
                      <img
                        :src="require(`@/static/img_events/SEJ enventos_adjuntos.svg`)"
                        alt="logo"
                        width="16"
                        height="16"
                        style="margin-right: 0.6rem"
                      />
                      <span class="title-normal"> Adjuntar archivos </span>
                    </button>
                  </a-upload>
                </a-form-item>
              </a-col>
              <a-col :lg="15" :xs="24">
                <span class="form-font helper-text">
                  Los archivos seleccionados no deben pesar más de 20 MB en conjunto. Archivos permitidos: pdf, jpg,
                  png, jpng, word, excel.
                </span>
              </a-col>
            </a-row>
          </a-form>
        </VerticalFormStyleWrap>
      </FormValidationWrap>
    </div>
  </a-row>
</template>

<script>
import { ref, reactive } from 'vue';
import { Notification } from 'ant-design-vue';
import { FormValidationWrap, VerticalFormStyleWrap } from '../Style';

const FileContainer = {
  name: 'FileContainer',
  components: { VerticalFormStyleWrap, FormValidationWrap },
  methods: {},
  props: {
    files: Array,
    sendForm: null,
    eventForm: Object,
  },
  watch: {
    sendForm: function() {
      this.submit();
    },
  },
  emits: ['getFormValues'],
  async setup(props, { emit }) {
    const formRef = ref();
    let totalWeight = 0;
    const maxWeight = 20971520; //20 megabytes

    const formState = reactive({
      id: props.index,
      layout: 'vertical',
    });
    
      console.log(props);

    const rules = {
      files: [
        {
          required: true,
          message: 'Este campo es requerido',
          trigger: 'blur',
          type: 'array',
        },
      ],
    };

    const handleError = message => {
      Notification['error']({
        message: 'Error',
        description: message,
      });
    };

    const chooseFiles = file => {
      const auxWeight = totalWeight + file.size;
        console.log(emit)

      if (auxWeight <= maxWeight) {
        formRef.value.clearValidate()
      //   props.eventForm.setFields({
      //     files: {
      //       errors: [],
      //     },
      // });
        props.files.push(file);
        totalWeight = auxWeight;
      } else {
        handleError(`El documento ${file.name} excede el peso permitido en la carga de archivos (20Mb).`);
      }
      return false;
    };

    const deleteFile = file => {
      const index = props.files.indexOf(file);
      if (file instanceof File) {
        totalWeight = totalWeight - file.size;
      }
      props.files.splice(index, 1);
    };

    const submit = () => {
      formRef.value
        .validate()
        .then(() => {
          emit('getFormValues', { is_ok: true, typeForm: 'files' });
        })
        .catch(() => {
          emit('getFormValues', { is_ok: false, typeForm: 'files' });
        });
    };

    return { rules, formRef, formState, chooseFiles, deleteFile, handleError, submit };
  },
};

export default FileContainer;
</script>

<style lang="sass">
.form-font
  font-family: Nutmeg
  &.helper-text
    color: #636668
  &.title
    font-weight: bold
    padding: 0

.btn-fill-add
  border: 1px solid #e70e4c
  color: #ffffff
  padding: 5px 15px 5px 15px
  background: #e70e4c
  border-radius: 7px
button:hover
  cursor: pointer

.btn-outlined-exit
  border: 1px solid #636668
  color: #636668
  padding: 5px 15px 5px 15px
  background: none
  border-radius: 7px
  margin-left: 2rem

.btn-add-outlined
  background-color: white
  border: 2px solid #e70e4c
  border-radius: 20px
  color: #e70e4c
  padding: .5rem 1.5rem

.btn-add-outlined.disabled
  border: 2px solid #CCCCCC
  color: #CCCCCC
.card
  background-color: #fff !important
  padding: 1rem
  border-top: 0.5px solid #bdbdbd
</style>
